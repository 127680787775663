.payment-form{
  .payment-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: var(--primary-color);
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
    width: 100%;
    align-self: center;
  
      &:hover{
        color: #fff;
        cursor: pointer;
        background-color: var(--secondary-color);
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  
    }
  }
  
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 10px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  .billing-info {
    width: 100%;
    margin-bottom: 20px;

    span {
      font-size: 1em;
      margin-bottom: 10px;
    }

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      // input,
      // .StripeElement {
      //   padding: 10px;
      //   font-size: 16px;
      //   border: 1px solid #ccc;
      //   border-radius: 4px;
      //   margin-top: 5px;
      // }
    }
  }
  
  .terms {
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    align-self: center;

    span {
      font-size: 0.875em;
      color: #666;

      a {
        color: var(--primary-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  input::placeholder {
    color: #aab7c4;
  }
  
  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElement.PaymentRequestButton {
    height: 40px;
  }

}



